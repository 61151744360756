<template>
  <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-1"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                  >
                    Form Gabung Kelas
                  </h3>
                  <div class="form-group">
                    <label>Kode Kelas</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      name="address1"
                      placeholder="BHSIND234"
                    />
                    <span class="form-text text-muted"
                      >Masukkan Nama Pelajaran</span
                    >
                  </div>
                  <div class="form-group">
                    <label>Nama Kelas</label>
                    <select class="form-control">
                      <option v-for="list in classname" :key="list.id">
                        {{ list.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div>
                    <button
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    >
                      Gabung Kelas
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
              <!--end: Wizard Form-->
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";
import JwtService from "@/core/services/jwt.service";
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      classname: [],
    };
  },
  name: "join-class",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Classroom", route: "/classroom" },
      { title: "Gabung Kelas" },
    ]);

    if (JwtService.getToken()) {
      //("get token true");
      ApiService.setHeader();
      ApiService.get("api/classrooms")
        .then((response) => {
          //(response);
          this.setClassName(response.data.classrooms);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    } else {
      Swal.fire({
        title: "",
        text: "Anda sudah login diperangkat lain",
        icon: "error",
      });
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "Kelas Berhasil ditambah!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    setClassName(data) {
      this.classname = data;
    },
  },
};
</script>
